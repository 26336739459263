.Hero {
  background: linear-gradient(
    311.4deg,
    rgba(245, 245, 245, 0.3) 19.14%,
    rgba(245, 245, 245, 0) 57.64%
  );
  background-color: #0071fe;
}

.Header {
  background: linear-gradient(
    311.4deg,
    rgba(245, 245, 245, 0.3) 19.14%,
    rgba(245, 245, 245, 0) 57.64%
  );
  background-color: #0071fe;
}

.pixels {
  position: absolute;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 35%;
  max-width: none;
  z-index: -10;
  height: 960px;
}

body {
  background: white;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;
}

.logos-slide {
  display: inline-block;
  animation: 100s slide infinite linear;
}

/* .logos-slide img {
  display: inline-block;
  height: 50px;
  margin: 0 80px;
} */

@font-face {
  font-family: "SharpSans-Bold";
  src:
    local("SharpSansforBAM-Bold"),
    url("./fonts/woff/SharpSansforBAM-Bold.woff") format("woff");
}

@font-face {
  font-family: "SharpSans-Medium";
  src:
    local("SharpSansforBAM-Medium"),
    url("./fonts/woff/SharpSansforBAM-Medium.woff") format("woff");
}

@font-face {
  font-family: "SharpSans-Semibold";
  src:
    local("SharpSansforBAM-SemiBold"),
    url("./fonts/woff/SharpSansforBAM-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "SpaceGroteskRegular";
  src:
    local("SpaceGrotesk-Regular"),
    url("./fonts/space_grotesk/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGroteskLight";
  src:
    local("SpaceGrotesk-Light"),
    url("./fonts/space_grotesk/SpaceGrotesk-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SpaceGroteskMedium";
  src:
    local("SpaceGrotesk-Medium"),
    url("./fonts/space_grotesk/SpaceGrotesk-Medium.ttf") format("truetype");
}
